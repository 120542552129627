<template>
  <div class="specials section-pad position-relative" id="app_pages">
    <div class="container">
      <h3 class="text-center fw-bold"> {{ $t('site.appPages') }} </h3>
      <p class="fw-6 text-center grayColor"> {{ $t('site.pagesDesc') }} </p>

      <section class="app-section">
        <div class="back-ph">
          <img :src="require('@/assets/imgs/centered.png')" alt="" />
        </div>
        <div class="catogry-carousel owl-carousel">
          <div class="pad-slider">
            <swiper
              :pagination="pagination"
              :modules="modules"
              :breakpoints="{
                '1024': {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
                '767': {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                '300': {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
              }"
              :slides-per-view="2"
              :centeredSlides="true"
              :space-between="20"
              :navigation="true"
              class="mySwiper"
              :autoplay="{
                delay: 4500,
                disableOnInteraction: false,
              }"
              :loop="true"
            >

            <swiper-slide class="home-item">
            <img class="home-img" :src="require('@/assets/imgs/Image 8 (1).png')" alt="">
          </swiper-slide>
          <swiper-slide class="home-item">
            <img class="home-img" :src="require('@/assets/imgs/app1.png')" alt="">
          </swiper-slide>
          <swiper-slide class="home-item">
            <img class="home-img" :src="require('@/assets/imgs/app2.png')" alt="">
          </swiper-slide>
          <swiper-slide class="home-item">
            <img  class="home-img" :src="require('@/assets/imgs/app3.png')" alt="">
          </swiper-slide>
          <swiper-slide class="home-item">
            <img class="home-img" :src="require('@/assets/imgs/app4.png')" alt="">
          </swiper-slide>
         <swiper-slide class="home-item">
            <img class="home-img" :src="require('@/assets/imgs/Image 8 (1).png')" alt="">
          </swiper-slide>
          <swiper-slide class="home-item">
            <img class="home-img" :src="require('@/assets/imgs/Image 8 (1).png')" alt="">
          </swiper-slide>


            </swiper>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>


<script>
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default {
  props: ["appImages"  , "introSite"],
  data() {
    return {

    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      pagination: {
        clickable: true,
      },

      modules: [Pagination, Autoplay, Navigation],
    };
  },
  methods: {},

  mounted() {},
};
</script>


<style>
/* slider*/
.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-item {
  width: 100%;
}
.app-section .home-item .home-img {
  width: 206px;
  height: 430px;
  margin: auto;
  border-radius: 0 0 28px 28px;
}
.back-ph {
  position: absolute;
  left: 50%;
  top: 15px;
  -webkit-transform: translateX(-50%) scale(1.1);
  transform: translateX(-50%) scale(1.1);
}
.back-ph img {
  opacity: 1;
  width: 210px;
  height: 433px;
}
.app-section {
  position: relative;
}
.app-section .swiper-slide {
  transition: all 0.4s ease-in-out;
}
.app-section .swiper-slide:not(.swiper-slide-active) {
  transform: scale(0.9);
  transition: all 0.4s ease-in-out;
}
.pad-slider {
  padding: 30px 0;
}
.flex-group-me {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.app-section .swiper-pagination {
  display: none;
}
.app-section .swiper-button-prev {
  position: absolute;
  right: 40% !important;
  top: 100%;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: #fff;
  display: none;
}
.app-section .swiper-button-next {
  position: absolute;
  left: 40% !important;
  top: 100%;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: #fff;
  display: none;
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}
</style>